import React from "react"

import "./record-your-voice.css"

const RecordYourVoiceContent = props => {
  // const titleData = [
  //   {
  //     id: 1,
  //     textData: "What needs",
  //     className: "one",
  //   },
  //   {
  //     id: 2,
  //     textData: "to change so",
  //     className: "two",
  //   },
  //   {
  //     id: 3,
  //     textData: "more women",
  //     className: "three",
  //   },
  //   {
  //     id: 4,
  //     textData: "can reach their",
  //     className: "four d-lg-none",
  //   },
  //   {
  //     id: 5,
  //     textData: "full potential?",
  //     className: "five d-lg-none",
  //   },
  //   {
  //     id: 6,
  //     textData: "can reach",
  //     className: "four d-none d-lg-block",
  //   },
  //   {
  //     id: 7,
  //     textData: "their full",
  //     className: "five d-none d-lg-block",
  //   },
  //   {
  //     id: 8,
  //     textData: "potential?",
  //     className: "six d-none d-lg-block",
  //   },
  // ]

  return (
    <>
      <div className="content content-record-your-voice">
        <h4 className="title title-record-your-voice">
          Be part of our global study, by telling us:
        </h4>
        <h1 className="title title-record-your-voice">
          <span className="one">What needs </span>
          <span className="two">to change so </span>
          <span className="three">more women </span>
          <span className="four d-lg-none">can reach their </span>
          <span className="five d-lg-none">full potential? </span>
          <span className="four d-none d-lg-block">can reach </span>
          <span className="five d-none d-lg-block">their full </span>
          <span className="six d-none d-lg-block">potential? </span>
          {/* {titleData.map(title => (
            <span key={title.id} className={title.className}>
              {title.textData}
            </span>
          ))} */}
        </h1>
        <a
          href="https://survey.phonic.ai/5fae0acd31623c02be2ca836"
          className="btn btn-add-voice btn-record-your-voice"
          id="survey-en"
          target="_blank"
          rel="noopener noreferrer"
        >
          Add Your Voice Now
        </a>
        <div
          className="transition-control"
          onTransitionEnd={() => props.setScrollControl(true)}
        >
          Transition Control Element
        </div>
      </div>
    </>
  )
}

export default RecordYourVoiceContent
