import React, { useState } from "react"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
// import YouTube from "react-youtube"

import ReactPlayer from "react-player/youtube"

// import IconPlayAudio from "../../images/icons/icon-play-audio.png"
// import IconPlayAudioActive from "../../images/icons/icon-play-audio-active.png"

import VideoOnePoster from "../../images/video-posters/en/jam-stewart.jpg"
import VideoTwoPoster from "../../images/video-posters/en/helen-yuan.jpg"
import VideoThreePoster from "../../images/video-posters/en/alex-hunter-dunn.jpg"
import VideoFourPoster from "../../images/video-posters/en/anasuya-ray.jpg"
import VideoFivePoster from "../../images/video-posters/en/meagan-hill.jpg"
import VideoSixPoster from "../../images/video-posters/en/linda-rennings.jpg"
import VideoSevenPoster from "../../images/video-posters/en/monica-glass.jpg"
import VideoEightPoster from "../../images/video-posters/en/ameesha-durham.jpg"
import VideoNinePoster from "../../images/video-posters/en/tumi-masekela.jpg"

import VideoTenPoster from "../../images/video-posters/en/pilar-gonzalez.jpg"
import VideoElevenPoster from "../../images/video-posters/en/yelile-habib.jpg"

const HearOtherWomenVoicesRight = () => {
  const [videoOne, setVideoOne] = useState(false)
  const [videoTwo, setVideoTwo] = useState(false)
  const [videoThree, setVideoThree] = useState(false)
  const [videoFour, setVideoFour] = useState(false)
  const [videoFive, setVideoFive] = useState(false)
  const [videoSix, setVideoSix] = useState(false)
  const [videoSeven, setVideoSeven] = useState(false)
  const [videoEight, setVideoEight] = useState(false)
  const [videoNine, setVideoNine] = useState(false)
  const [videoTen, setVideoTen] = useState(false)
  const [videoEleven, setVideoEleven] = useState(false)

  const settings = {
    arrows: true,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    beforeChange: () => {
      setVideoOne(false)
      setVideoTwo(false)
      setVideoThree(false)
      setVideoFour(false)
      setVideoFive(false)
      setVideoSix(false)
      setVideoSeven(false)
      setVideoEight(false)
      setVideoNine(false)
      setVideoTen(false)
      setVideoEleven(false)
    },
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: false,
        },
      },
    ],
  }

  // const opts = {
  //   height: "100%",
  //   width: "100%",
  //   playerVars: {
  //     origin: props.origin,
  //     // autoplay: 1,
  //     rel: 0,
  //   },
  // }

  // const _onReadyOne = event => {
  //   event.target.playVideo()
  // }
  // const _onReadyTwo = event => {
  //   event.target.playVideo()
  // }
  // const _onReadyThree = event => {
  //   event.target.playVideo()
  // }
  // const _onReadyFour = event => {
  //   event.target.playVideo()
  // }
  // const _onReadyFive = event => {
  //   event.target.playVideo()
  // }
  // const _onReadySix = event => {
  //   event.target.playVideo()
  // }
  // const _onReadySeven = event => {
  //   event.target.playVideo()
  // }
  // const _onReadyEight = event => {
  //   event.target.playVideo()
  // }
  // const _onReadyNine = event => {
  //   event.target.playVideo()
  // }

  return (
    <>
      <div
        className="content carousel-hear-other-women-voices"
        // onTouchEnd={props.setSection("hear-other-women-voices")}
      >
        <Slider {...settings}>
          <div>
            <div className="other-women-voices-slide iframe-hldr ratio ratio-16x9">
              {!videoOne && (
                <figure
                  className="mb-0 video-poster"
                  onClick={() => setVideoOne(true)}
                >
                  <img className="img-fluid" src={VideoOnePoster} alt="" />
                </figure>
              )}
              {videoOne && (
                <ReactPlayer
                  url="https://youtu.be/oJRR3RXqCcE"
                  width="100%"
                  height="100%"
                  controls={true}
                  stopOnUnmount={true}
                />
                // <YouTube
                //   videoId="oJRR3RXqCcE"
                //   opts={opts}
                //   onReady={_onReadyOne}
                // />
              )}
              {/* {!videoOne && (
                <figure
                  className="mb-0 icon-play-audio"
                  onClick={() => setVideoOne(true)}
                >
                  <img className="img-fluid" src={IconPlayAudio} alt="" />
                </figure>
              )} */}
            </div>
          </div>
          <div>
            <div className="other-women-voices-slide iframe-hldr ratio ratio-16x9">
              {!videoTen && (
                <figure
                  className="mb-0 video-poster"
                  onClick={() => setVideoTen(true)}
                >
                  <img className="img-fluid" src={VideoTenPoster} alt="" />
                </figure>
              )}
              {videoTen && (
                <ReactPlayer
                  url="https://youtu.be/JTBZtE37MFI"
                  width="100%"
                  height="100%"
                  controls={true}
                  stopOnUnmount={true}
                />
                // <YouTube
                //   videoId="JTBZtE37MFI"
                //   opts={opts}
                //   onReady={_onReadyOne}
                // />
              )}
              {/* {!videoOne && (
                <figure
                  className="mb-0 icon-play-audio"
                  onClick={() => setVideoOne(true)}
                >
                  <img className="img-fluid" src={IconPlayAudio} alt="" />
                </figure>
              )} */}
            </div>
          </div>
          <div>
            <div className="other-women-voices-slide iframe-hldr ratio ratio-16x9">
              {!videoTwo && (
                <figure
                  className="mb-0 video-poster"
                  onClick={() => setVideoTwo(true)}
                >
                  <img className="img-fluid" src={VideoTwoPoster} alt="" />
                </figure>
              )}
              {videoTwo && (
                <ReactPlayer
                  url="https://youtu.be/D_5fmlsujPM"
                  width="100%"
                  height="100%"
                  controls={true}
                  stopOnUnmount={true}
                />
                // <YouTube
                //   videoId="D_5fmlsujPM"
                //   opts={opts}
                //   onReady={_onReadyTwo}
                // />
              )}
              {/* {!videoTwo && (
                <figure
                  className="mb-0 icon-play-audio"
                  onClick={() => setVideoTwo(true)}
                >
                  <img className="img-fluid" src={IconPlayAudio} alt="" />
                </figure>
              )} */}
            </div>
          </div>
          <div>
            <div className="other-women-voices-slide iframe-hldr ratio ratio-16x9">
              {!videoThree && (
                <figure
                  className="mb-0 video-poster"
                  onClick={() => setVideoThree(true)}
                >
                  <img className="img-fluid" src={VideoThreePoster} alt="" />
                </figure>
              )}
              {videoThree && (
                <ReactPlayer
                  url="https://youtu.be/1xNRTvHz7Ss"
                  width="100%"
                  height="100%"
                  controls={true}
                  stopOnUnmount={true}
                />
                // <YouTube
                //   videoId="1xNRTvHz7Ss"
                //   opts={opts}
                //   onReady={_onReadyThree}
                // />
              )}
              {/* {!videoThree && (
                <figure
                  className="mb-0 icon-play-audio"
                  onClick={() => setVideoThree(true)}
                >
                  <img className="img-fluid" src={IconPlayAudio} alt="" />
                </figure>
              )} */}
            </div>
          </div>
          <div>
            <div className="other-women-voices-slide iframe-hldr ratio ratio-16x9">
              {!videoFour && (
                <figure
                  className="mb-0 video-poster"
                  onClick={() => setVideoFour(true)}
                >
                  <img className="img-fluid" src={VideoFourPoster} alt="" />
                </figure>
              )}
              {videoFour && (
                <ReactPlayer
                  url="https://youtu.be/ZRmgyJumN30"
                  width="100%"
                  height="100%"
                  controls={true}
                  stopOnUnmount={true}
                />
                // <YouTube
                //   videoId="ZRmgyJumN30"
                //   opts={opts}
                //   onReady={_onReadyFour}
                // />
              )}
              {/* {!videoFour && (
                <figure
                  className="mb-0 icon-play-audio"
                  onClick={() => setVideoFour(true)}
                >
                  <img className="img-fluid" src={IconPlayAudio} alt="" />
                </figure>
              )} */}
            </div>
          </div>
          <div>
            <div className="other-women-voices-slide iframe-hldr ratio ratio-16x9">
              {!videoFive && (
                <figure
                  className="mb-0 video-poster"
                  onClick={() => setVideoFive(true)}
                >
                  <img className="img-fluid" src={VideoFivePoster} alt="" />
                </figure>
              )}
              {videoFive && (
                <ReactPlayer
                  url="https://youtu.be/dJPA8U1LmLQ"
                  width="100%"
                  height="100%"
                  controls={true}
                  stopOnUnmount={true}
                />
                // <YouTube
                //   videoId="dJPA8U1LmLQ"
                //   opts={opts}
                //   onReady={_onReadyFive}
                // />
              )}
              {/* {!videoFive && (
                <figure
                  className="mb-0 icon-play-audio"
                  onClick={() => setVideoFive(true)}
                >
                  <img className="img-fluid" src={IconPlayAudio} alt="" />
                </figure>
              )} */}
            </div>
          </div>
          <div>
            <div className="other-women-voices-slide iframe-hldr ratio ratio-16x9">
              {!videoSix && (
                <figure
                  className="mb-0 video-poster"
                  onClick={() => setVideoSix(true)}
                >
                  <img className="img-fluid" src={VideoSixPoster} alt="" />
                </figure>
              )}
              {videoSix && (
                <ReactPlayer
                  url="https://youtu.be/DbgdUpXZKlo"
                  width="100%"
                  height="100%"
                  controls={true}
                  stopOnUnmount={true}
                />
                // <YouTube
                //   videoId="DbgdUpXZKlo"
                //   opts={opts}
                //   onReady={_onReadySix}
                // />
              )}
              {/* {!videoSix && (
                <figure
                  className="mb-0 icon-play-audio"
                  onClick={() => setVideoSix(true)}
                >
                  <img className="img-fluid" src={IconPlayAudio} alt="" />
                </figure>
              )} */}
            </div>
          </div>
          <div>
            <div className="other-women-voices-slide iframe-hldr ratio ratio-16x9">
              {!videoSeven && (
                <figure
                  className="mb-0 video-poster"
                  onClick={() => setVideoSeven(true)}
                >
                  <img className="img-fluid" src={VideoSevenPoster} alt="" />
                </figure>
              )}
              {videoSeven && (
                <ReactPlayer
                  url="https://youtu.be/pGolDZXLQ3Q"
                  width="100%"
                  height="100%"
                  controls={true}
                  stopOnUnmount={true}
                />
                // <YouTube
                //   videoId="pGolDZXLQ3Q"
                //   opts={opts}
                //   onReady={_onReadySeven}
                // />
              )}
              {/* {!videoSeven && (
                <figure
                  className="mb-0 icon-play-audio"
                  onClick={() => setVideoSeven(true)}
                >
                  <img className="img-fluid" src={IconPlayAudio} alt="" />
                </figure>
              )} */}
            </div>
          </div>
          <div>
            <div className="other-women-voices-slide iframe-hldr ratio ratio-16x9">
              {!videoEight && (
                <figure
                  className="mb-0 video-poster"
                  onClick={() => setVideoEight(true)}
                >
                  <img className="img-fluid" src={VideoEightPoster} alt="" />
                </figure>
              )}
              {videoEight && (
                <ReactPlayer
                  url="https://youtu.be/1M--qT1CjCM"
                  width="100%"
                  height="100%"
                  controls={true}
                  stopOnUnmount={true}
                />
                // <YouTube
                //   videoId="1M--qT1CjCM"
                //   opts={opts}
                //   onReady={_onReadyEight}
                // />
              )}
              {/* {!videoEight && (
                <figure
                  className="mb-0 icon-play-audio"
                  onClick={() => setVideoEight(true)}
                >
                  <img className="img-fluid" src={IconPlayAudio} alt="" />
                </figure>
              )} */}
            </div>
          </div>
          <div>
            <div className="other-women-voices-slide iframe-hldr ratio ratio-16x9">
              {!videoNine && (
                <figure
                  className="mb-0 video-poster"
                  onClick={() => setVideoNine(true)}
                >
                  <img className="img-fluid" src={VideoNinePoster} alt="" />
                </figure>
              )}
              {videoNine && (
                <ReactPlayer
                  url="https://youtu.be/01XcxxdKjUk"
                  width="100%"
                  height="100%"
                  controls={true}
                  stopOnUnmount={true}
                />
                // <YouTube
                //   videoId="01XcxxdKjUk"
                //   opts={opts}
                //   onReady={_onReadyNine}
                // />
              )}
              {/* {!videoNine && (
                <figure
                  className="mb-0 icon-play-audio"
                  onClick={() => setVideoNine(true)}
                >
                  <img className="img-fluid" src={IconPlayAudio} alt="" />
                </figure>
              )} */}
            </div>
          </div>
          <div>
            <div className="other-women-voices-slide iframe-hldr ratio ratio-16x9">
              {!videoEleven && (
                <figure
                  className="mb-0 video-poster"
                  onClick={() => setVideoEleven(true)}
                >
                  <img className="img-fluid" src={VideoElevenPoster} alt="" />
                </figure>
              )}
              {videoEleven && (
                <ReactPlayer
                  url="https://youtu.be/aFMiGopiE9U"
                  width="100%"
                  height="100%"
                  controls={true}
                  stopOnUnmount={true}
                />
                // <YouTube
                //   videoId="aFMiGopiE9U"
                //   opts={opts}
                //   onReady={_onReadyNine}
                // />
              )}
              {/* {!videoNine && (
                <figure
                  className="mb-0 icon-play-audio"
                  onClick={() => setVideoNine(true)}
                >
                  <img className="img-fluid" src={IconPlayAudio} alt="" />
                </figure>
              )} */}
            </div>
          </div>
        </Slider>
      </div>
    </>
  )
}

export default HearOtherWomenVoicesRight
