import React from "react"

import "./thankyou.css"

const Thankyou = props => {
  return (
    <>
      <div className="content content-thankyou">
        <h1 className="title title-thankyou">
          <span className="one">Thank you </span>
          <span className="two">for using </span>
          <span className="three">your voice to </span>
          <span className="four">help shape </span>
          <span className="five">tomorrow. </span>
        </h1>
        <div
          className="transition-control"
          onTransitionEnd={() => {
            props.setSection("thankyou social-media")
          }}
        >
          Transition Control Element
        </div>
      </div>
    </>
  )
}

export default Thankyou
