import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"

import { Dropdown } from "react-bootstrap"

import Facebook from "../images/icons/social-media/facebook.png"
import Instagram from "../images/icons/social-media/instagram.png"
import Linkedin from "../images/icons/social-media/linkedin.png"
import Twitter from "../images/icons/social-media/twitter.png"
import Youtube from "../images/icons/social-media/youtube.png"

const Header = props => {
  const imageData = useStaticQuery(graphql`
    query {
      fullPotentialText: file(
        relativePath: { eq: "full-potential-white.png" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)
  return (
    <>
      <header>
        {/* <header className={props.headerBg}> */}
        <nav className={`main-nav`}>
          <div className="container">
            <div className="row align-items-center">
              <span
                className="navbar-brand"
                onClick={() => {
                  props.setSection("record-your-voice")
                  props.setNavOpen("")
                  props.setCommunityVideo("")
                  props.setCommitments("")
                }}
              >
                Mars Logo
              </span>
              <span
                className="nav-toggle open-btn d-xl-none"
                onClick={() => props.setNavOpen("nav-open")}
              >
                Nav Open Button
              </span>
              <span
                className="nav-toggle close-btn d-xl-none"
                onClick={() => props.setNavOpen("")}
              >
                Nav Close Button
              </span>
              <div className="nav-hldr d-flex flex-column justify-content-between w-auto">
                <ul className="d-xl-flex">
                  <li>
                    <div
                      className="record-your-voice-btn"
                      id="record-your-voice-en"
                      onClick={() => {
                        props.setSection("record-your-voice")
                        props.setNavOpen("")
                        props.setCommunityVideo("")
                        props.setCommitments("")
                      }}
                    >
                      Add your voice <span className="sr-only">(current)</span>
                    </div>
                  </li>
                  <li>
                    <div
                      className="the-community-btn"
                      id="the-community-en"
                      onClick={() => {
                        props.setSection("the-community")
                        props.setNavOpen("")
                        props.setCommunityVideo("")
                        props.setCommitments("")
                      }}
                    >
                      The community
                    </div>
                  </li>
                  <li>
                    <div
                      className="full-potential-btn"
                      id="full-potential-platform-en"
                      onClick={() => {
                        props.setSection("full-potential")
                        props.setNavOpen("")
                        props.setCommunityVideo("slide-up")
                        props.setCommitments("")
                      }}
                    >
                      Full Potential platform
                    </div>
                  </li>
                  <li>
                    <div
                      className="commitments-btn"
                      id="actions-en"
                      onClick={() => {
                        props.setSection("commitments commitments-stickers")
                        props.setNavOpen("")
                        props.setCommunityVideo("slide-up")
                      }}
                    >
                      {/* 2020 commitments */}
                      Actions
                    </div>
                  </li>
                  <li>
                    <div
                      className="hear-other-women-voices-btn"
                      id="hear-other-women-voices-en"
                      onClick={() => {
                        props.setSection("hear-other-women-voices")
                        props.setNavOpen("")
                        props.setCommunityVideo("slide-up")
                        props.setCommitments("slide-up")
                      }}
                    >
                      Hear other women&#8217;s voices
                    </div>
                  </li>
                </ul>
                <div className="hamburger-bottom d-xl-none">
                  <div className="language-switcher">
                    <p className="text-white">Language</p>
                    <div className="d-flex flex-wrap">
                      <Link
                        to="/be_heard/"
                        activeStyle={{ color: "#00d7b9" }}
                        className="my-1"
                        hrefLang="en"
                      >
                        English
                      </Link>
                      <Link
                        to="/be_heard/fr"
                        activeStyle={{ color: "#00d7b9" }}
                        className="my-1"
                        hrefLang="fr"
                      >
                        French
                      </Link>
                      <Link
                        to="/be_heard/mx"
                        activeStyle={{ color: "#00d7b9" }}
                        className="my-1"
                        hrefLang="es"
                      >
                        Spanish
                      </Link>
                      <Link
                        to="/be_heard/pt"
                        activeStyle={{ color: "#00d7b9" }}
                        className="my-1"
                        hrefLang="pt"
                      >
                        Portuguese
                      </Link>
                    </div>
                  </div>
                  <div className="social-media-links-footer">
                    <p className="text-white">Follow Us</p>
                    <div className="d-flex align-items-center">
                      <a
                        href="https://www.facebook.com/Mars"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img className="facebook me-4" src={Facebook} alt="" />
                      </a>
                      <a
                        href="https://www.instagram.com/marsglobal"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          className="instagram me-4"
                          src={Instagram}
                          alt=""
                        />
                      </a>
                      <a
                        href="https://twitter.com/Marsglobal"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img className="twitter me-4" src={Twitter} alt="" />
                      </a>
                      <a
                        href="https://www.linkedin.com/company/mars"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img className="linkedin me-4" src={Linkedin} alt="" />
                      </a>
                      <a
                        href="https://www.youtube.com/user/Mars"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img className="youtube me-4" src={Youtube} alt="" />
                      </a>
                    </div>
                  </div>
                  <div className="terms-policy">
                    <div>
                      <a
                        href="https://www.mars.com/legal"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Terms &amp; Conditions
                      </a>
                    </div>
                    <div>
                      <a
                        href="https://www.mars.com/privacy-policy"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Privacy Policy
                      </a>
                    </div>
                  </div>
                  <figure className="mb-0 full-potential-text">
                    <Img
                      fluid={imageData.fullPotentialText.childImageSharp.fluid}
                      alt=""
                    />
                  </figure>
                </div>
              </div>

              <div className="d-none d-xl-block w-auto">
                <Dropdown>
                  <Dropdown.Toggle variant="success" id="dropdown-basic">
                    English
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    {/* <Dropdown.Item href="/fr">French</Dropdown.Item>
                    <Dropdown.Item href="/mx">Spanish</Dropdown.Item>
                    <Dropdown.Item href="/pt">Portuguese</Dropdown.Item> */}
                    <Link
                      to="/be_heard/fr"
                      className="dropdown-item"
                      hrefLang="fr"
                    >
                      French
                    </Link>
                    <Link
                      to="/be_heard/mx"
                      className="dropdown-item"
                      hrefLang="es"
                    >
                      Spanish
                    </Link>
                    <Link
                      to="/be_heard/pt"
                      className="dropdown-item"
                      hrefLang="pt"
                    >
                      Portuguese
                    </Link>
                  </Dropdown.Menu>
                </Dropdown>
              </div>

              <a
                href="https://survey.phonic.ai/5fae0acd31623c02be2ca836"
                id="survey-en"
                className="icon-mic"
                target="_blank"
                rel="noopener noreferrer"
              >
                Icon Mic
              </a>
            </div>
          </div>
        </nav>
      </header>

      {/* <Navbar className="fixed-top main-nav" expand="lg">
        <Container>
          <span className="navbar-brand" id="landing-btn">
            Mars Logo
          </span>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <a href="https://google.com" className="icon-mic d-lg-none">
              Icon Mic
            </a>
            <span className="navbar-brand" id="landing-btn-m d-lg-none">
              Mars Logo
            </span>
            <ul className="navbar-nav me-auto">
              <li className="nav-item">
                <div
                  className="nav-link record-your-voice-btn"
                  id="record-your-voice-btn"
                >
                  Record your voice <span className="sr-only">(current)</span>
                </div>
              </li>
              <li className="nav-item">
                <div
                  className="nav-link the-community-btn"
                  id="the-community-btn"
                >
                  The Community
                </div>
              </li>
              <li className="nav-item">
                <div
                  className="nav-link full-potential-btn"
                  id="full-potential-btn"
                >
                  Full Potential Platform
                </div>
              </li>
              <li className="nav-item">
                <div className="nav-link commitments-btn" id="commitments-btn">
                  2020 Commitments
                </div>
              </li>
              <li className="nav-item">
                <div
                  className="nav-link hear-other-women-voices-btn"
                  id="hear-other-women-voices-btn"
                >
                  Hear other women's voices
                </div>
              </li>
            </ul>
            <a href="https://google.com" className="icon-mic d-none d-lg-block">
              Icon Mic
            </a>
          </Navbar.Collapse>
        </Container>
      </Navbar> */}
    </>
  )
}

export default Header
