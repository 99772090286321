import React from "react"

import "./full-potential.css"

const FullPotentialContent = props => {
  return (
    <>
      <div className="content content-full-potential">
        <h2 className="title title-full-potential">
          <span className="one">The Mars</span>
          <span className="two">Full Potential</span>
          <span className="three">platform</span>
        </h2>
        <h4 className="title title-full-potential">
          In the world we want tomorrow, society is inclusive. And all women are
          reaching their full potential.
        </h4>
        <p className="para para-full-potential">
          The evidence is clear that unlocking opportunities for women, creates
          a better world for everyone. That&#8217;s why we&#8217;re making
          significant changes across our places of work, our sourcing
          communities, and our advertising to help ensure that everyone has the
          opportunity to reach their full potential.
        </p>
        <a
          href="https://www.mars.com/sustainability-plan/thriving-people/unlocking-opportunities-for-women"
          target="_blank"
          rel="noopener noreferrer"
          className="btn btn-mars-blue rounded-0 btn-full-potential"
          id="learn-more-en"
        >
          Learn more
        </a>
        <div
          className="transition-control"
          onTransitionEnd={() => props.setScrollControl(true)}
        >
          Transition Control Element
        </div>
      </div>
    </>
  )
}

export default FullPotentialContent
